import { cacheKeys, cacheManager } from '../cache-manager';
import { processors } from './appSettingsManager.service';
import logger from '../logger';

export const appSettingsManager = {
  async init(settings) {
    const existingSettings = cacheManager.get(cacheKeys.APP_SETTINGS) || {};
    settings = { ...existingSettings, ...settings };

    const ordersMetaData = await processors.processPatientOrdersForComparison(settings);

    cacheManager.set(cacheKeys.APP_SETTINGS, { ...settings, ...ordersMetaData });

    logger
      .to('host')
      .info(`app settings keys: \n ${JSON.stringify(Object.keys(settings))}`)
      .data({ module: 'app settings manager' })
      .end();
  },

  addSetting(key, value) {
    const newValue = {};
    newValue[key] = value;
    const existingSettings = cacheManager.get(cacheKeys.APP_SETTINGS);
    const settings = { ...existingSettings, ...newValue };
    cacheManager.set(cacheKeys.APP_SETTINGS, settings);
  },

  getAppSettingsByValue(value) {
    const settings = cacheManager.get(cacheKeys.APP_SETTINGS);
    return settings ? settings[value] : undefined;
  },

  getAppSettings() {
    return cacheManager.get(cacheKeys.APP_SETTINGS) || {};
  },
};
