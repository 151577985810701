import { Slice } from 'react-pie-menu';
import styled from 'styled-components';

export const StyledSlice = styled(Slice)`
  background: white;
  box-shadow: inset 15px 4px 90px rgba(51, 189, 250, 0.12);
  color: rgba(0, 103, 172, 0.8);
  &[_highlight][enabled='true'],
  &:active[enabled='true'] {
    color: white;
    cursor: pointer;
    background: #00adef;
  }

  &[enabled='false'] {
    cursor: unset;
    color: #b5d3e7;
    background: rgba(224, 237, 244, 0.4);
    & #explorer-icon > svg > path {
      stroke: #7f7f7f;
    }
    &[explorer='overview'] {
      #explorer-icon > svg > path:nth-last-child(2) {
        fill: #7f7f7f;
        stroke: #7f7f7f;
      }
    }
  }

  &[enabled='true'][loading_state='false'] {
    animation-name: fadeIn;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.8s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
