import { getEnvironmentParams, compileUrl, addHeaders } from './apiService-helper';

const requestApiCall = ({ selector, pathParams, queryParams, options = {}, headers = {} }) => {
  const { baseUrl, url, defaultOptions, method } = getEnvironmentParams(selector);
  const requestURL = compileUrl({ baseUrl, url, pathParams, queryParams });
  const props = { method, headers: addHeaders({ headersObj: headers }), ...defaultOptions, ...options };
  return fetch(requestURL, props);
};

const requestStatic = ({ url, options = {}, headers = {} }) => {
  const { method } = options;
  const { defaultOptions } = getEnvironmentParams();
  const props = {
    method: method || 'GET',
    headers: addHeaders({ headersObj: headers }),
    ...defaultOptions,
    ...options,
  };

  return fetch(url, props);
};

/**
 * @param url: for satic files request a complette 'url' can be used
 * @param selector: name of key from API map (apiMap.ts)
 * @param pathParams: params embedded in path that will be replaced by their key. Looks like this: <%=pathParmaKey%>. see example below
 * @param queryParams: params that appear following the question mark as <key>=<value>. see example below
 * @param options: http call options. see interface IApiCall
 * @param showLoader: predicate on whether the loadeing spinner is visible
 * Example of request function usage:
 * for API Calls request:
 * request({ selector: 'GetItrFile', pathParams: { orderId: 11111 }, queryParams:
 * { fromGenericApi: false} })
 *
 * in apiMap a url should not include the base url, should include any variable pathParams in a template placeholder <%=pathParmaKey%>.
 * So an example url would be:
 * /mvc/orderfiles/GetItrFile/<%=params%>
 *
 * the queryParams will be compiled onto it and it will look like this:
 * /mvc/orderfiles/GetItrFile?path=<some RXID>/<>
 *
 * Then, the base url is added. For example, the baseUrl is 'http://localhost:54386', and thus the compiled URL will look like this:
 * http://localhost:54386/mvc/orderfiles/GetItrFile?orderId=17708130&fromGenericApi=false.
 *
 * for Static files request:
 * from any resource - request({ url: <any valid URL 'http://.....'> })
 * from localhost dev - request({ url: /models/<any ITR/NIRI file name> })
 */
export default ({ url, selector, pathParams, queryParams, options = {}, headers = {} }) => {
  return url
    ? requestStatic({ url, options, headers })
    : requestApiCall({ selector, pathParams, queryParams, options, headers });
};
