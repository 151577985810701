import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './ConditionNavigation.module.css';
import ConditionButton from './ConditionButton/ConditionButton';
const ConditionNavigation = ({ explorers, activeExplorer }) => {
  const dvRef = useRef(null);
  const sortedExplorers = [...explorers].sort((a, b) => a.positionInNavigationPanel - b.positionInNavigationPanel);
  const getExplorerLength = (length) => {
    let explorersWidth = 0;
    if (dvRef.current !== null) {
      for (let i = 1; i < dvRef.current.children.length; i++) {
        explorersWidth = explorersWidth + dvRef.current.children[i].clientWidth;
      }
    } else {
      explorersWidth = length * 164;
    }
    return explorersWidth;
  };
  return (
    <div className={styles.navigationContainer} ref={dvRef}>
      <div
        id="dvConditionNavigationContainer"
        style={{ '--width': `calc(5vw * ${explorers.length - 1} + ${getExplorerLength(explorers.length)}px)` }}
        className={styles.guidedTourCSS}
      ></div>
      {sortedExplorers.map((explorer) => (
        <ConditionButton key={explorer.id} explorer={explorer} isActive={explorer.id === activeExplorer} />
      ))}
    </div>
  );
};

ConditionNavigation.propTypes = {
  /**
   * Array of explorers
   */
  explorers: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Explorer id
       */
      id: PropTypes.string.isRequired,
      /**
       * Call back to active explorer
       */
      activateExplorer: PropTypes.func.isRequired,
      /**
       * explorer title
       */
      title: PropTypes.string.isRequired,
      /**
       * Is explorer enabled boolean
       */
      enabled: PropTypes.bool.isRequired,
    })
  ).isRequired,
  /**
   * Current active explorer id
   */
  activeExplorer: PropTypes.string,
};

export default ConditionNavigation;
