import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../../Button';
import styles from './ConditionButton.module.css';

const ConditionButton = ({ explorer, isActive }) => {
  return (
    <>
      <Button
        className={classNames(styles.button, isActive ? styles.active : '')}
        onClick={explorer.activateExplorer}
        data-test-id={`test-${explorer.id}`}
        id={explorer.id}
      >
        {explorer.title}
      </Button>
    </>
  );
};

ConditionButton.propTypes = {
  explorer: PropTypes.shape({
    /**
     * Explorer id
     */
    id: PropTypes.string.isRequired,
    /**
     * Call back to active explorer
     */
    activateExplorer: PropTypes.func.isRequired,
    /**
     * explorer title
     */
    title: PropTypes.string.isRequired,
    /**
     * Is explorer enabled boolean
     */
    enabled: PropTypes.bool.isRequired,
  }),
  /**
   * is current explorer activated boolean
   */
  isActive: PropTypes.bool,
};

export default ConditionButton;
