import { cacheKeys, cacheManager } from '../cache-manager';
import { utils } from '../utils';

export const processors = {
  processPatientOrdersForComparison: (settings) => {
    return new Promise((resolve) => {
      const { getOrderId, getRxGuid } = utils;
      const currentOrderId = getOrderId();
      const currentRxGuid = getRxGuid();
      const { getPatientOrdersForComparison } = settings || [];

      const ordersMetaData = getPatientOrdersForComparison
        ? getPatientOrdersForComparison.map((order) => ({
            ...order,
            IsCurrentOrder: (() => {
              const { orderId, rxGuid } = order || {};
              const isOrderId = currentOrderId && orderId && parseInt(orderId, 10) === parseInt(currentOrderId, 10);
              const isRxGuid = currentRxGuid && rxGuid && rxGuid === currentRxGuid;
              return !!(isOrderId || isRxGuid);
            })(),
          }))
        : [];

      ordersMetaData.sort((a, b) => new Date(a.scanDate) - new Date(b.scanDate));

      const orderDateObject = cacheManager.get(cacheKeys.SELECTED_ORDER_DATE) || {
        current: null,
        selected: null,
      };

      if (ordersMetaData.length > 0) {
        const currentOrder = ordersMetaData.find((order) => order.IsCurrentOrder);
        const { scanDate } = currentOrder || {};
        const currentSelectedOrderDate = scanDate || null;
        orderDateObject.current = currentSelectedOrderDate;
      }

      cacheManager.set(cacheKeys.SELECTED_ORDER_DATE, orderDateObject);
      return resolve(ordersMetaData.length > 0 ? { getPatientOrdersForComparison: ordersMetaData } : {});
    });
  },
};
