export const menuTypes360 = {
  CIRCULAR: 'circular',
  CONDITION_NAVIGATION: 'condition_navigation',
  NONE: 'none',
};

export const explorers = {
  TOOTH_HEALTH: 'tooth_health',
  GUM_HEALTH: 'gum_health',
  BITE: 'bite',
  ALIGNMENT: 'alignment',
  OVERVIEW: 'overview',
};
