import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { StyledSlice } from '../styled/Slice';
import { ReactComponent as ToothHealthIcon } from './icons/tooth_health.svg';
import { ReactComponent as GumHealthIcon } from './icons/gum_health.svg';
import { ReactComponent as BiteIcon } from './icons/bite.svg';
import { ReactComponent as OverviewIcon } from './icons/overview.svg';
import { ReactComponent as AlignmentIcon } from './icons/alignment.svg';

import styles from './MenuSlice.module.css';

export const explorersIcons = {
  tooth_health: <ToothHealthIcon />,
  gum_health: <GumHealthIcon />,
  bite: <BiteIcon />,
  alignment: <AlignmentIcon />,
  overview: <OverviewIcon />,
};

const MenuSlice = ({ explorer, fontSize, onSelect, explorerStyles, isActiveExplorer }) => {
  const sliceRef = useRef(null);
  const { explorerIconStyle, explorerContentStyle } = explorerStyles;
  const explorerTextStyle = classNames(styles.explorerText, isActiveExplorer ? styles.activeExplorer : '');

  useEffect(() => {
    if (explorer.enabled) {
      const currentRef = sliceRef.current;
      const activateExplorer = () => onSelect(explorer);

      currentRef.addEventListener('click', activateExplorer);
      return () => {
        currentRef.removeEventListener('click', activateExplorer);
      };
    }
  }, [explorer, onSelect]);

  return (
    <StyledSlice
      key={explorer.id}
      contentHeight="160px"
      attrs={{
        ref: sliceRef,
        enabled: `${explorer.enabled}`,
        explorer: `${explorer.id}`,
        loading_state: 'false',
      }}
    >
      <div className={explorerContentStyle} id={explorer.id}>
        <div className={explorerIconStyle} id="explorer-icon">
          {explorersIcons[explorer.id]}
        </div>
        <div className={explorerTextStyle} id={explorer.id} style={{ fontSize }}>
          {explorer.title}
        </div>
      </div>
    </StyledSlice>
  );
};

MenuSlice.propTypes = {
  explorer: PropTypes.shape({
    /**
     * Explorer id
     */
    id: PropTypes.string.isRequired,
    /**
     * Call back to active explorer
     */
    activateExplorer: PropTypes.func.isRequired,
    /**
     * explorer title
     */
    title: PropTypes.string.isRequired,
    /**
     * Is explorer enabled boolean
     */
    enabled: PropTypes.bool.isRequired,
  }),
  /**
   * calculated font size
   */
  fontSize: PropTypes.string,
};

export default MenuSlice;
