import React, { useEffect } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../../../plugins/src/presets/Layout.module.css';

const renderLayout = (zones) => {
  return zones.map((zone) => {
    const { component: ZoneContainerComponent } = zone;
    return <ZoneContainerComponent {...zone.props} key={zone.id} id={zone.id} />;
  });
};

const AppPreset = (props) => {
  const { className: classNameProp, zones, style, presetLoaded } = props;
  const className = classNames(styles.layout, classNameProp);
  useEffect(() => {
    zones && presetLoaded();
  }, [zones, presetLoaded]);

  return (
    <div className={className} style={style} data-test-id="layout">
      {zones && renderLayout(zones)}
    </div>
  );
};

AppPreset.propTypes = {
  /**
   * Class name
   */
  className: PropTypes.string,
  /**
   * Layout zones
   */
  zones: PropTypes.array.isRequired,
  /**
   * Style
   */
  style: PropTypes.object,

  /**
   * Style
   */
  presetLoaded: PropTypes.func,
};

AppPreset.defaultProps = {
  className: null,
  style: null,
  presetLoaded: noop,
};

export default AppPreset;
